import React, { useEffect, useState, Component } from "react"
import HeroSection from "../components/LandingPage/HeroSection/HeroSection"
import GetInTouch from "../components/LandingPage/GetInTouch/GetInTouch"
import LogosSlider from "../components/LandingPage/LogosSlider/LogosSlider"
import AlternatingRows from "../components/LandingPage/AlternatingRows/AlternatingRows"
import FullWidthRows from "../components/LandingPage/FullWidthRows/FullWidthRows"
import { LandingPageStyles } from "../components/LandingPage/Layout.styles"
import Layout from "../components/Global/Layout/Layout-landing-page"
import SEO from "../components/Global/SEO/seo"

class LandingPage extends Component {
  
  render() {
    let post = this.props.data.contentfulLandingPage

    const pageRows = post.landingPageRowsReference;

    let CustomRows = [];
    let heroSectionRow;
    let getInTouchRow;
    let rowAlternation;
    let logoImageSlider;
    let iteration = 0;
    
    if (post.title && post.description && post.description.json && post.backgroundColour[0]) {
      heroSectionRow = <HeroSection title={post.title} description={post.description.json} backgroundColor={post.backgroundColour[0]} />
    }
    if (post.getInTouchDescription && post.getInTouchDescription.json) {
      getInTouchRow = <GetInTouch description={post.getInTouchDescription.json}/>;
    }

    if (pageRows) {
      pageRows.forEach(function(element) {
        if (element.isRowAlternating) {
          if (iteration % 2 == 0) {
            rowAlternation = 'even';
          }
          else{
            rowAlternation = 'odd';
          }
          CustomRows.push(<AlternatingRows rowData={element} rowAlternation={rowAlternation} />);
          iteration++;
        }
        else{
          CustomRows.push(<FullWidthRows rowData={element} backgroundColor={"bg-row-white"} />);
        }
      });  
    }

    //checks that there are at least 3 logos for the logo slider
    if (post.logoSliderImages && post.logoSliderImages.length >= 3) {
      logoImageSlider = <LogosSlider images={post.logoSliderImages}/>;
    }

    return (
      <Layout logoDark={post.backgroundColour[0]} >
        <SEO title={`Landing Page | ${post.title}`} />
        {heroSectionRow}
        {logoImageSlider}
        {CustomRows}
        {getInTouchRow}
      </Layout>
    )  
  }
}

export default LandingPage


export const query = graphql`
  query getLandingPagePosts($slug: String) {
    contentfulLandingPage(slug: { eq: $slug }) {
      title
      slug
      backgroundColour
      description {
        json
      }
      getInTouchDescription {
        json
      }
      logoSliderImages {
        fluid(quality: 75, maxWidth: 150, maxHeight: 75) {
          ...GatsbyContentfulFluid
        }
        title
      }
      landingPageRowsReference {
        title
        rowBackgroundColour
        isRowAlternating
        description {
          json
        }
        buttonLinkTitle
        buttonLinkLocation
        image {
          fluid{
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
  `
  