import React, { useEffect } from 'react';
import { Button, Col, Container, Form, Row, Alert } from "react-bootstrap"
import SideBar from "../SideBar/SideBar"
import { LINKS } from "../../../data/pageLinks.data"
import { Link } from "gatsby"

const HubspotSalesFormCompact = () => {
  useEffect(() => {
    // Load the HubSpot script dynamically
    const script = document.createElement('script');
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    script.async = true;
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "eu1",
          portalId: "141862351",
          formId: "a0347588-29fc-4574-bcc6-c3654d31f1a1",
          target: '#hubspot-form'
        });
      }
    };
    document.body.appendChild(script);

    // Clean up on component unmount
    return () => {
      document.body.removeChild(script);
    };
  });

  return (<div id="hubspot-form"></div>);
};

export default HubspotSalesFormCompact;
