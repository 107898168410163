import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import { FullWidthRowsWrapper } from "./FullWidthRows.styles"
import ApButton from "../../Global/Common/ApButton/ApButton"
import Image from "gatsby-image"
import {documentToReactComponents} from "@contentful/rich-text-react-renderer"
import { Link } from "gatsby"

const FullWidthRows = ({rowData}) => {

  const title = rowData.title;
  const backgroundColor = rowData.rowBackgroundColour;
  const description = documentToReactComponents(rowData.description.json)
  const linkName = rowData.buttonLinkTitle;
  const linkLocation = rowData.buttonLinkLocation;
  const image = rowData.image;

  let bgColor = 'bg-row-green'
  let buttonColor = 'primary';
  let textColor = 'row-text-white';  
  let linkedButton;
  let renderedImage;
  let colPadding;
  
  if (backgroundColor == 'Blue') {
    buttonColor = 'greenTwo';
    bgColor = 'bg-row-blue';
  }

  if (linkName && linkLocation) {
    linkedButton = 
    <a href={linkLocation}>
      <ApButton
        variant={buttonColor}
        className="explore-features px-4 nunito"
        role="link"
      >
        {linkName}
      </ApButton>
    </a>;
  }

  if (backgroundColor == 'White') {
    textColor = 'text-dark-green';
    bgColor = 'bg-row-white';
  }

  if (image) {
    renderedImage = <Image fluid={image.fluid} />;
    colPadding = "mb-5";
  }


  return (
    <FullWidthRowsWrapper className={"py-5 px-4 px-sm-5 text-left text-md-center " + bgColor}>
      <Container className="py-5 px-0">
        <Row className={'justify-content-center '+ textColor}>
          <Col lg={12} className={colPadding}>
            <h2 className="h1 mb-4 secondary-blue-2-text nunito">
              {title}
            </h2>
            <p>
              {description}
            </p>
            {linkedButton}
          </Col>
          <Col
            lg={6}
            md={8}
          >
            {renderedImage}
          </Col>
        </Row>
      </Container>
    </FullWidthRowsWrapper>
  )
}

export default FullWidthRows
