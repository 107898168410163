import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const LogoImages = ({LogoImages}) => {

  let logosArray = [];

  //below code makes sure that there are at least 21 images for the logo slider
  let iteration = Math.floor(21/LogoImages.length);
  if (21 % LogoImages.length != 0) {
    iteration++;
  }

  for (let i = 0; i < iteration; i++) {
    LogoImages.map((logo) => (
      logosArray.push(<p><img src={logo.fluid.src} alt={logo.title}/></p>)
    ))
  }

  return (
    <div class="slider-container" id="homepageLogosSlider">
    <div class="scroll-disabler"></div>
      {logosArray}
    </div>
  )
}

export default LogoImages

