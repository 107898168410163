import React from "react"
import { StyledLogosSlider } from "./LogosSlider.style"
import { useEffect } from "react"
import LogoImages from "./LogoImages"

const LogosSlider = ({images}) => {

  useEffect(() => {
    const homepageLogosSlider = document.getElementById('homepageLogosSlider');
    const homepageSliderWidth = homepageLogosSlider.scrollWidth;

        const interval = setInterval(() => {
          const first = document.querySelector('#homepageLogosSlider p');

        if(!isElementInViewport(first)){
            homepageLogosSlider.appendChild(first);
            homepageLogosSlider.scrollLeft = (homepageLogosSlider.scrollLeft - first.offsetWidth);
            }
            if (homepageLogosSlider.scrollLeft !== homepageSliderWidth) {
            homepageLogosSlider.scrollLeft = (homepageLogosSlider.scrollLeft + 1);
            }
        }, 15);

        function isElementInViewport (el) {
            var rect = el.getBoundingClientRect();
            return rect.right > 0;
        }
       return () => clearInterval(interval);
  }, [])

  return (
    <StyledLogosSlider className="pt-4 pb-4 pb-md-5 pt-md-5 d-block">
      <LogoImages LogoImages={images}/>
    </StyledLogosSlider>

  )
}

export default LogosSlider
