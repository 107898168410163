import styled from "styled-components"
import { fonts, colors } from "../../../src/utils/styles"

export const LandingPageStyles = styled.div`
	font-family: ${fonts.poppins};
	min-width: 320px;

	.nunito {
		font-family:${fonts.nunito}
	}
`