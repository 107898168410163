import styled from "styled-components"
import { fonts, colors } from "../../../utils/styles"


export const GetInTouchWrapper = styled.section`
  width: 100%;
  color: #144056;
  .max-height-lg{
    @media (min-width: 992px) {
      height: 100%;
    }
  }
  .container{
    max-width: 1170px;
    h2{
      font-size: 3rem;
      &.text-green{
        color: #39B8BD;
      }
    }
    p {
      font-size: 20px;
      font-family: ${fonts.poppins};
    }
    ul{
      li{
        .checked-icon {
          max-height: 58px;
        }
        list-style-type: none;
        @media (max-width: 991px) {
          &:before {
            content: "✓";
            font-family: 'Nunito Sans';
            font-weight: bold;
            font-size: large;
            color: ${colors.secondaryBlue2};
            padding-right: 15px;
          }  
        }
      }
    }
    
  }
  .blue-aqua{
    color:#4CB9EF
  }
  .getInTouchImage{
    border-radius: 40px;
  }
`
