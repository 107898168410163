import * as React from "react"

function SvgComponent(props) {
  return (
    <svg fill="none" viewBox="0 0 103 88" {...props}>
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={31}
        y={14}
        width={68}
        height={45}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31 14h68v45H31V14z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.913 51.848l17.24-14.47c8.912-7.479 17.84-14.936 26.72-22.45 1.83-1.549 3.725-.923 4.599.164.775.965.792 3.035-.595 4.179-5.745 4.739-11.425 9.558-17.13 14.348-9.613 8.073-19.229 16.144-28.841 24.219-2.026 1.701-3.55 1.545-5.202-.537-4.783-6.024-9.57-12.046-14.354-18.069-.278-.35-.568-.695-.814-1.068-.904-1.376-.639-3.106.602-4.026 1.31-.97 3.02-.756 4.076.558 1.948 2.42 3.868 4.864 5.804 7.295 2.496 3.133 4.995 6.262 7.494 9.392.114.142.238.276.401.465z"
          fill="#55B9A3"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44 88c24.3 0 44-19.7 44-44S68.3 0 44 0 0 19.7 0 44s19.7 44 44 44z"
        fill="#4A90E2"
        fillOpacity={0.11}
      />
    </svg>
  )
}

export default SvgComponent
