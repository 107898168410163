import styled from "styled-components"

import Navbar from "react-bootstrap/Navbar"

import { colors, fonts } from "../../../utils/styles"

const { primaryBlue, secondaryBlue2 } = colors

export const NavbarWrapper = styled(Navbar)`
  z-index: 999;
  width: 100%;
  position: absolute;
  .nav-container{
    max-width: 1170px;
  }
`
