import React from "react"
import { HeroWrapper } from "./HeroSection.styles"
import { Row, Col, Container } from "react-bootstrap"
import Checked from "./Checked/Checked"
import {documentToReactComponents} from "@contentful/rich-text-react-renderer"
import {BLOCKS} from "@contentful/rich-text-types"

const HeroSection = ({title, description, backgroundColor}) => {
  let textColor = 'hero-text-white'
  let bgColor = 'bg-hero-green';
  if (backgroundColor == "White" ){
    bgColor = 'bg-hero-white';
    textColor = 'hero-text-green';
  }
  else if (backgroundColor == "Blue" ){
    bgColor = 'bg-hero-blue';
  }

  const RICHTEXT_OPTIONS = {
    renderNode: {
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return <li className="d-flex align-items-center mb-4"><Checked className="checked-icon mr-4 d-none d-lg-block" />{children}</li>;
    },
    [BLOCKS.UL_LIST]: (node, children) => {
      return <ul className="py-3 pl-0">{children}</ul>
    }
    }
  }

  return (
    <HeroWrapper Tag="section" className={bgColor + " " + textColor}>
      <div className="max-height-lg px-4 px-sm-5 pb-5 pb-lg-0">
        <Container className="d-flex flex-column justify-content-around max-height-lg px-0 pb-5 pb-lg-0">
          <Row className="flex-column-reverse flex-lg-row ">
            <Col lg={6} xm={12} className="d-flex flex-column justify-content-center px-3 pl-lg-5 px-lg-3">
              <h1 className="text-green hero-section-heading">
                { title }
              </h1>
              <div className="pt-4">{documentToReactComponents(description, RICHTEXT_OPTIONS)}</div>
            </Col>
          </Row>
        </Container>
      </div>
    
    </HeroWrapper>
  )
}

export default HeroSection
