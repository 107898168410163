import styled from "styled-components"
import { fonts, colors } from "../../../utils/styles"


export const HeroWrapper = styled.section`
  background-color: #144056;
  background: linear-gradient(180.59deg, #144056 67.68%, #39B8BD 111.1%);
  background-blend-mode: overlay, normal;
  box-shadow: 0px 28px 63px -38px rgba(0, 0, 0, 0.5);
  @media (min-width: 992px) {
      height: calc(100vh - 136px);
      padding-top: 0px;
  }
  width: 100%;
  min-height: 980px;
  padding-top: 134px;
  &.bg-hero-green{
    background: linear-gradient(180.59deg, #144056 67.68%, #39B8BD 111.1%);
  }
  &.bg-hero-blue{
    background: linear-gradient(180deg, #0F6CB6 65.32%, #46AC92 116.12%);
  }
  &.bg-hero-white{
    background: linear-gradient(180deg, #F4F4F4 78.71%, #39B8BD 149.43%);
  }
  &.hero-text-green{
    color: #144056;
  }
  &.hero-text-white{
    color: #FFF;
    p{
      color: #FFF;
    }
  }
  .max-height-lg{
    @media (min-width: 992px) {
      height: 100%;
    }
  }
  .container{
    max-width: 1170px;
    h1{
      font-size: 3rem;
      &.text-green{
        color: #39B8BD;
      }
    }
    p {
      font-size: 20px;
      font-family: ${fonts.poppins};
    }
    ul{
      li{
        font-size: 16px;
        list-style-type: none;
        .checked-icon {
          height: 70px;
        }
        p {
          font-size: 16px;
          margin: 0;
        }
        @media (max-width: 991px) {
          &:before {
            content: "✓";
            font-family: 'Nunito Sans';
            font-weight: bold;
            font-size: large;
            color: ${colors.secondaryBlue2};
            padding-right: 15px;
          }  
        }
      }
    }
    
  }
`
