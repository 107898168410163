import React from "react"
import { GetInTouchWrapper } from "./GetInTouch.styles"
import { Row, Col, Container } from "react-bootstrap"
import HubspotSalesFormCompact from '../../ContactUsPage/ContactForm/HubspotSalesFormCompact'
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import {documentToReactComponents} from "@contentful/rich-text-react-renderer"

const GetInTouch = ({description}) => {
  const data = useStaticQuery(graphql`
  {
    allFile(filter: {relativeDirectory: {eq: "landing-page"}, name: {eq: "hero"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 550) {
                ...GatsbyImageSharpFluid
              }
          }
        }
      }
    }
  }  
  `)

  const image = data.allFile.edges[0];

  return (
    <GetInTouchWrapper Tag="section">
      <div className="px-4 px-sm-5 py-5">
        <Container className="d-flex flex-column justify-content-around px-0 py-5 my-lg-5 my-0 text-left text-md-center text-lg-left">
          <Row className="flex-column-reverse flex-lg-row ">
            <Col lg={6} xm={12} className="text-center text-lg-left mt-3 mt-lg-0"><HubspotSalesFormCompact /></Col>
            <Col lg={6} xm={12} className="d-flex flex-column px-3 pl-lg-5 px-lg-3">
              <h2 className="h1 hero-section-heading blue-aqua">
                Get In Touch
              </h2>
              <p className="py-4">{documentToReactComponents(description)} </p>
              <Image fluid={image && image.node.childImageSharp.fluid} className="getInTouchImage d-none d-lg-block" />
            </Col>
          </Row>
        </Container>
      </div>
    
    </GetInTouchWrapper>
  )
}

export default GetInTouch
